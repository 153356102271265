<template>
  <div>
    <Toolbar :handle-reset="resetForm" :handle-submit="onCreateSeminarPackage" :handle-copy="copy" :enableInvoiceApproval="true" :approveInvoice="approveInvoice" />
    <SeminarPackageForm
      :errors="violations"
      :values="item"
      ref="updateForm"
      refForm="updateForm"
      :loading="isLoading"
    />
    <Loading :visible="isLoading" />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { createHelpers } from 'vuex-map-fields';
import SeminarPackageForm from '../../components/seminarPackage/Form.vue';
import Loading from '../../components/Loading';
import Toolbar from '../../components/Toolbar';
import CreateMixin from '../../mixins/CreateMixin';
import UpdateMixin from '../../mixins/UpdateMixin';

import { mapGetters } from 'vuex';

const servicePrefix = 'SeminarPackage';

const { mapFields } = createHelpers({
  getterType: 'seminarPackage/getField',
  mutationType: 'seminarPackage/updateField'
});

export default {
  name: 'SeminarPackageUpdate',
  servicePrefix,
  mixins: [CreateMixin, UpdateMixin],
  components: {
    Loading,
    Toolbar,
    SeminarPackageForm
  },
  data() {
    return {
      item: {},
      parent: {}
    };
  },
  computed: {
    ...mapFields(['error', 'isLoading', 'created', 'deleted', 'violations', 'handled', 'handleAction']),
    ...mapFields('seminarPackage', {
    }),
    ...mapGetters('seminarPackage', ['find']),
  },
  methods: {
    ...mapActions('seminarPackage', {
      create: 'create',
      retrieve: 'load',
      update: "update",
      actionSeminarPackage: 'action'
    }),
    onCreateSeminarPackage() {
      if (this.$route.params.parent) {
        this.$refs.createForm.item.parent = this.$route.params.parent;
      }
      this.onSendForm();
    },
    copy() {
      this.actionSeminarPackage({ item: this.item, action: 'copy' });
    },
    approveInvoice() {
      this.$confirm(this.$t('InvoiceApprovalSeminarPackage'), {
        buttonTrueText: this.$t('Yes'),
        buttonFalseText: this.$t('No'),
      }).then((response) => {
        if (!response) {
          return;
        }
        this.actionSeminarPackage({ item: this.item, action: 'generateAndSendParticipantsInvoice' });
      });
    }
  },
  created() {
    this.parent = this.$route.params.parent ? this.find(this.$route.params.parent) : {};
  },
  watch: {
    handled() {
      if (this.handleAction === 'copy') {
        this.showMessage(this.$t('SeminarPackageCopied'));
        this.$router.push({
          name: 'SeminarPackageUpdate',
          params: { id: this.handled['@id'] }
        });
        this.handleAction = null;
        this.handled = null;
      }
      if (this.handleAction === 'generateAndSendParticipantsInvoice') {
        this.showMessage(this.$t('SeminarPackageInvoiceApproved'));
        this.handleAction = null;
        this.handled = null;
      }
    }
  },
};
</script>
